<template>
    <div>
        <template v-if="params.value">
            <div class="flex justify-between">
                <div class="flex-grow min-w-0">
                    <a class="block truncate w-full" :href="`/search?registrant_email=${encodeURIComponent(params.value)}`" target="_blank">{{ params.value }}</a>
                </div>
                <feather-icon icon="EyeIcon" svgClasses="h-4 w-4 ml-4 cursor-pointer" @click="viewDecryptedValue()"/>

            </div>

            <vs-prompt id="password-prompt" vs-title="View Decrypted Value" @vs-cancel="closePasswordPrompt" @vs-close="closePasswordPrompt" :vs-buttons-hidden="true" :vs-active.sync="password['prompt']">
                <div>
                    <vs-input v-model="password['input']" class="w-full" placeholder="Enter password"/>
                </div>
            </vs-prompt>

            <vs-prompt id="decrypted-email-prompt" vs-title="View Decrypted Email" @vs-cancel="closeDecryptedEmailPrompt" @vs-close="closeDecryptedEmailPrompt" :vs-buttons-hidden="true" :vs-active.sync="decrypted['prompt']">
                <vs-alert active="true" class="h-auto" :class="{ 'mb-4': !!(decrypted['value']) }">
                    <div class="break-all">
                        <template v-if="decrypted['value']"><a :href="`/search?registrant_email=${encodeURIComponent(params.value)}`" target="_blank">{{ decrypted['value'] }}</a></template>
                        <template v-else>Value is being decrypted, please wait...</template>
                    </div>
                </vs-alert>
                <div v-if="decrypted['value']" class="vx-row">
                    <div class="vx-col w-1/2">
                        <vs-button v-if="!(!!(decrypted['whitelist']))" color="dark" type="border" class="w-full" @click="whitelistConfirm()">Whitelist</vs-button>
                        <vs-button v-else color="dark" type="border" class="w-full" @click="whitelistConfirm()">De-Whitelist</vs-button>
                    </div>
                    <div class="vx-col w-1/2">
                        <vs-button v-if="!(!!(decrypted['blacklist']))" color="dark" type="border" class="w-full" @click="blacklistConfirm()">Blacklist</vs-button>
                        <vs-button v-else color="dark" type="border" class="w-full" @click="blacklistConfirm()">De-Blacklist</vs-button>
                    </div>
                </div>
            </vs-prompt>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'CellRendererEncryptedEmail',
		data() {
			return {
				password: {
					input: '',
					value: 'ea50da49e0be',
					prompt: false
				},
				decrypted: {
                    value: '',
                    whitelist: 0,
                    blacklist: 0,
					prompt: false
				},
			}
		},
        watch: {
			'password.input'() {
                if (this.password['input'] == this.password['value']) {
                    this.acceptPasswordPrompt();
                }
            }
        },
        methods: {
			viewDecryptedValue() {
                // Check PIN cache
                if (this.getLocalStorageWithExpiry('encryptedEmail')) {
                    // Open Email Prompt
                    this.openDecryptedEmailPrompt();
                }
                else {
                    // Open Password Prompt
                    this.password['prompt'] = true;
                }
            },
			closePasswordPrompt() {
                this.password['input'] = '';
                this.password['prompt'] = false;
            },
			acceptPasswordPrompt() {
                // Close Password Prompt
                this.closePasswordPrompt();

                // Set cache for PIN
                this.setLocalStorageWithExpiry('encryptedEmail', this.params.value, 1800000);

                // Open Email Prompt
                this.openDecryptedEmailPrompt();
            },
			openDecryptedEmailPrompt() {
                this.decrypted['value'] = '';
                this.decrypted['prompt'] = true;
                
                // API call to get value
                this.$axiosSecure.get('/decrypt', {
                    params: {
                        email: this.params.value
                    }
                })
                .then((response) => {
                    this.decrypted['value'] = response.data.decrypted_email != undefined ? response.data.decrypted_email : this.params.value;
                    this.decrypted['whitelist'] = Number(response.data.whitelisted_email);
                    this.decrypted['blacklist'] = Number(response.data.blacklisted_email);
                })
                .catch((error) => {
                    // Error notification
                    this.$vs.notify({
                        color: 'danger',
                        title: 'Something went wrong',
                        text: 'Please contact the server admin'
                    });

                    console.log(error);
                });
            },
			closeDecryptedEmailPrompt() {
                // Reset Decrypted Prompt
                this.decrypted['prompt'] = false;
            },
			whitelistConfirm() {
                // Close Decrypted Prompt
                this.closeDecryptedEmailPrompt();

                // Check whitelist status
				if(this.decrypted['whitelist']) {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Remove the selected email from whitelist?',
						accept: this.whitelistEmail
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Whitelist the selected email?',
						accept: this.whitelistEmail
					});
				}
            },
            whitelistEmail() {
                // Check whitelist status
				if(this.decrypted['whitelist']) {
                    // De-Whitelist Axios Call
                    this.$axiosSecure.post('/de-whitelist-email', {
                        email: this.params.value
                    })
                    .then(() => {
                        // Confimation notification
                        this.$vs.notify({
                            color: 'success',
                            title: 'Whitelist success',
                            text: 'The selected email has been removed from whitelist'
                        });
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
                else {
                    // Whitelist Axios Call
                    this.$axiosSecure.post('/whitelist-email', {
                        email: this.params.value
                    })
                    .then((success) => {
                        if(success.data == "created") {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'success',
                                title: 'Whitelist success',
                                text: 'The selected email has been whitelisted'
                            });
                        }
                        else {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'warning',
                                title: 'Whitelist error',
                                text: 'The selected email is already whitelisted'
                            });
                        }
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
            },
			blacklistConfirm() {
                // Close Decrypted Prompt
                this.closeDecryptedEmailPrompt();

                // Check blacklist status
				if(this.decrypted['blacklist']) {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Remove the selected email from blacklist?',
						accept: this.blacklistEmail
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Blacklist the selected email?',
						accept: this.blacklistEmail
					});
				}
            },
            blacklistEmail() {
                // Check blacklist status
				if(this.decrypted['blacklist']) {
                    // De-Blacklist Axios Call
                    this.$axiosSecure.post('/remove-blacklisted-registrant-email', {
                        email: this.params.value
                    })
                    .then(() => {
                        // Confimation notification
                        this.$vs.notify({
                            color: 'success',
                            title: 'Blacklist success',
                            text: 'The selected email has been removed from blacklist'
                        });
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
                else {
                    // Blacklist Axios Call
                    this.$axiosSecure.post('/save-blacklisted-registrant-email', {
                        email: this.params.value
                    })
                    .then((success) => {
                        if(success.data == "created") {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'success',
                                title: 'Blacklist success',
                                text: 'The selected email has been blacklisted'
                            });
                        }
                        else {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'warning',
                                title: 'Blacklist error',
                                text: 'The selected email is already blacklisted'
                            });
                        }
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
            }
        }
    }
</script>
